import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Typography } from "@material-ui/core";
import { Button } from "react-bootstrap";

const CardCarousel = (props) => {
  return (
    <>
      <style type="text/css">
        {`

        // .card-carousel {
        //     // width : 500px;
        //     // margin: auto,
        //     margin-bottom : 50px;
        // }

      


      

        .card-img {
          height : 200px;
        }

        .price-tag {
          z-index : 999;
          transform: translate(10px, -370px);
          
        }

        .card-carousel {
          width : 300px;  
          margin-bottom : 50px;
        }

      

       


        // @media only screen and (max-width: 1200px) {
        //   .card-carousel {
        //     width : 450px;
        //   }

        //   .card-img {
        //     height : 250px;
        //   }

        //   .price-tag {
        //     z-index : 999;
        //     transform: translate(10px, -420px);
            
        //   }

        // }

        // @media only screen and (max-width: 600px) {
        //   .card-carousel {
        //     width : 350px;  
        //     margin-bottom : 50px;
        //   }

        //   .card-img {
        //     height : 200px;
        //   }

        //   .price-tag {
        //     z-index : 999;
        //     transform: translate(10px, -370px);
            
        //   }
  


        // }


        @media only screen and (min-width: 400px) {
          .card-carousel {
            width : 300px;  
            margin-bottom : 50px;
          }

          .card-img {
            height : 200px;
          }

          .price-tag {
            z-index : 999;
            transform: translate(10px, -370px);
            
          }
  


        }

        .customButton {
          border: 1px solid #1A64AC;
        }
        
        .customButton:hover {
          background-color: #1A64AC;
          color: white;
       
        }
    
        p.carousel-status {
          margin-right: 12px;
          margin-top: 12px;
      }
    
        `}
      </style>

      <div
        className="card-carousel"
        style={{
          height: "450px",
          margin: "auto",
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <Carousel
          autoPlay={true}
          showThumbs={false}
          style={{ borderRadius: 0 }}
        >
          {props.images.map((image, key) => (
            <div>
              <img
                alt={props.header}
                key={key}
                style={{
                  borderTopRightRadius: 25,
                  borderTopLeftRadius: 25,
                }}
                className="card-img"
                src={image}
              />
            </div>
          ))}
        </Carousel>

        <div
          style={{
            backgroundColor: "white",
            paddingTop: 10,
            paddingBottom: 30,
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomLeftRadius: 25,
            borderBottomRightRadius: 25,
            textAlign: "center",
          }}
        >
          <a
            href={props.page}
            rel="noreferrer"
            style={{
              fontFamily: "Urbanist",
              fontWeight: "600",
              fontSize: "20px",
              textAlign: "center",
              paddingLeft: 10,
              paddingRight: 10,
              padding: 0,

              margin: 0,
              color: "black",
            }}
          >
            {props.header}
          </a>

          <p
            style={{
              padding: 0,
              fontFamily: "Urbanist",
              fontWeight: "400",
              fontSize: "14px",
              textAlign: "center",
              margin: 0,
              paddingLeft: 10,
              paddingRight: 10,
              height: 46,
            }}
          >
            {props.subtitle}
          </p>

          {/* <div style={{ textAlign: "center", margin: 10, paddingBottom: 15 }}>
            {props.icons.map((icon) => (
              <img
                alt={props.header + " icons"}
                src={icon}
                style={{ marginRight: 15 }}
              />
            ))}
          </div> */}

          <a href={props.link} rel="noreferrer" target="_blank">
            {" "}
            <Typography
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                padding: 3,
                fontFamily: "Urbanist",
                fontWeight: "400",
                fontSize: "14px",
                textAlign: "center",
                color: "#0B3F89",
                margin: 0,
              }}
            >
              <img
                alt={props.header + " location"}
                width="17px"
                height="20px"
                src="assets/img/Group5/location-icon.png"
              />
              

              {props.location}

              <img
                alt={props.header + " external link"}
                src="assets/img/external-link.svg"
                style={{ marginLeft: 5, objectFit: "cover" }}
              />
            </Typography>
          </a>

          <Button
            style={{
              width: "150px",
              marginTop: 20,
              borderRadius: "25px",
            }}
            variant="flat-outline"
            target="_blank"
            size="md"
            pill
            className="customButton"
            href={props.linkRoute}
          >
            {props.nameRoute}
          </Button>
        </div>
        <a
          href={props.page}
          rel="noreferrer"
          target="_blank"
          className="price-tag"
          style={{
            textDecoration: "none",
            // backgroundColor: "#1A64AC",
            borderRadius: 5,
            padding: 15,
            display: "inline-block",
          }}
        >
          {/*<p*/}
          {/*  style={{*/}
          {/*    fontFamily: "Urbanist",*/}
          {/*    fontSize: 18,*/}
          {/*    fontWeight: 600,*/}
          {/*    color: "white",*/}
          {/*    margin: 0,*/}
          {/*    padding: 0,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {props.pricing}/ Yearly*/}
          {/*</p>*/}
        </a>
      </div>
    </>
  );
};

export default CardCarousel;
